import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'

const Currency = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts,
      isEdit,
      setIsEdit,
      isAdd,
      setIsAdd,
    } = useContext(MainContext)
    const [showModal, setShowModal] = useState(false);
    const link = 'https://admin.icoinn.club/icoinn_backend/admin/currency.php'
    let currColumns = ['CurrencyID','Currency', 'Rate', 'Edit']
    // let currencies = [
    //     {
    //         country: 'Kenya',
    //         currency: 'Kes',
    //         rate: '129'
    //     },
    //     {
    //         country: 'Tanzania',
    //         currency: 'Tsh',
    //         rate: '2650'
    //     }
    // ]
    const inputs = [
      {
        name: "currency",
        icon: 'fa-solid fa-cube',
        type: 'text',
        placeholder: 'Enter Currency symbol(eg Kes)',
      },
      {
        name: "rate",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter Rate(dollar/currency)',
      }
      
    ]
    function addCurrency(){
        window.location.replace('/edit')
    }
    const navigate = useNavigate()
    useEffect(() => {
      if (!localStorage.getItem('udm_unm')) {
        navigate('/')
      }
    }, [navigate])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={isAdd} value='Add New Currency' name='Currencies'/>
        <div className="w3-container">
            <Table tableName='currency' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} countries={countries} name='Currencies Available' columns={currColumns} data={currency} summary={false} edit={isEdit}/>
        </div>
    </div>
  )
}

export default Currency