import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'

const Admins = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts,
      isEdit,
      setIsEdit,
      isAdd,
      setIsAdd,
    } = useContext(MainContext)
    const [showModal, setShowModal] = useState(false);
    const link = 'https://admin.icoinn.club/icoinn_backend/admin/admin.php'
    let adminColumns = ['AdminID', 'Username', 'Password', 'Role', 'Currency', 'Permissions','Countries', 'type']
    // let admins = [
    //     {
    //         username: 'Bitech',
    //         role: 'Developer',
    //         countries: '[Kenya] [Tanzania]',
    //         currency: 'Kes',
    //         permission: '[edit] [add] [view]'

    //     },
    //     {
    //         username: 'Damien',
    //         role: 'Marketer',
    //         countries: '[Kenya]',
    //         currency: 'Kes',
    //         permission: '[view]'
    //     }
    // ]
    const inputs = [
      {
        name: "username",
        icon: 'fa-solid fa-cube',
        type: 'text',
        placeholder: 'Enter Username',
      },
      {
        name: "passwrd",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Password',
      },
      {
        name: "role",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Role'
      },
      {
        name: "currency",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter Currency',
      },
      {
        name: "permission",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter permisions(they should be only [edit] [view] [add] in this enclosed fromat)',
      },
      {
        name: "countries",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Countries(they should be in this from [countryCode eg:254])',
      }
    ]
    const navigate = useNavigate()
    useEffect(() => {
      if (!localStorage.getItem('udm_unm')) {
        navigate('/')
      }
    }, [navigate])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={isAdd} value='Add New Admin' name='Admin'/>
        <div className="w3-container">
            <Table tableName='admins' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} countries={countries} name='Admins' columns={adminColumns} data={admins} summary={false} edit={isEdit}/>
        </div>
    </div>
  )
}

export default Admins