import React, { createContext, useContext, useEffect, useState } from 'react'

export const MainContext = createContext()
function MainProvider(props) {
    const [transactions, setTransactions] = useState([])
    const [latestTransactions, setLatesTransactions] = useState()
    const [users, setUsers] = useState([])
    const [latestUsers, setLatestUsers] = useState()
    const [wallets, setWallets] = useState([])
    const [admins, setAdmins] = useState()
    const [payment, setPayment] = useState()
    const [groups, setGroups] = useState()
    const [currency, setCurrency] = useState([{
        currency: ''
    }])
    const [category, setCategory] = useState()
    const [controls, setControls] = useState()
    const [adminCurrency, setAdminCurrency] = useState({})
    const [countries, setCountries] = useState([])
    const [products, setProducts] = useState()
    const [admInf, setAdmInf] = useState()
    const[permission, setPermission] = useState([])
    const[isEdit, setIsEdit] = useState(false)
    const[isAdd, setIsAdd] = useState(false)
    const value = {
        transactions,
        setTransactions,
        latestTransactions,
        setLatesTransactions,
        users,
        setLatestUsers,
        wallets,
        setWallets,
        admins,
        setAdmins,
        payment,
        setPayment,
        groups,
        setGroups,
        currency,
        setCurrency,
        category,
        setCategory,
        controls,
        setControls,
        latestUsers,
        setUsers,
        adminCurrency,
        setAdminCurrency,
        countries,
        setCountries,
        products,
        setProducts,
        admInf,
        setAdmInf,
        isEdit,
        setIsEdit,
        isAdd,
        setIsAdd,

    }
    useEffect( () => {
        const userData = JSON.parse(localStorage.getItem('udm_unm'))
       const fetchData = async() => {
        try {
            const response = await fetch('https://admin.icoinn.club/icoinn_backend/admin/main.php', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
              })
              const result = await response.json()
              setTransactions(result.transactions)
              setLatesTransactions(result.latest_transactions)
              setUsers(result.users)
              setLatestUsers(result.latest_users)
              setWallets(result.wallets)
              setCountries(result.countries)
              setAdmins(result.admins)
              setPayment(result.banks)
              setProducts(result.products)
              setGroups(result.groups)
              setControls(result.controls)
              setAdminCurrency(result.admin_currency)
              setCurrency(result.currency)
              setCategory(result.countries)
              setAdmInf(result.adm_inf)
              setPermission(result.permissions)

        } catch (error) {
            console.error("Error:", error)
        }
       }
       fetchData()
    }, [])
    useEffect(() => {
      function setEd() {
        if(permission.indexOf('edit') != -1){
          setIsEdit(true)
        }
      }
      function setAd() {
        if(permission.indexOf('add') != -1){
          setIsAdd(true)
        }
      }
      setAd()
      setEd()
    }, [permission])
  return (
    <MainContext.Provider value={value}>
        {props.children}
    </MainContext.Provider>
  )
}

export default MainProvider