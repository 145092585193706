import React, { useContext, useEffect } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'

const Controls = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts
    } = useContext(MainContext)
    let contColumns = ['ID', 'Type', 'Minimum', 'Country']
    // let controls = [
    //     {
    //         type: 'Deposits',
    //         minimum: 'Kes 0.00'
    //     },
    //     {
    //         type: 'Withdraw',
    //         minimum: 'Kes 2.00'
    //     }
    // ]
    function addCont(){
        window.location.replace('/edit')
    }
    const navigate = useNavigate()
    useEffect(() => {
      if (!localStorage.getItem('udm_unm')) {
        navigate('/')
      }
    }, [navigate])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top handleBtn={addCont} value='Add New Controler' name='Controlers'/>
        <div className="w3-container">
            <Table name='Controls' columns={contColumns} data={controls} summary={false} edit={true}/>
        </div>
    </div>
  )
}

export default Controls